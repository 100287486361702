


























import Vue from 'vue'

export default Vue.extend({
  name: 'Search',

  data: function () {
    return {
      data: []
    }
  },

  created () {
    console.log(this.$root.data)
  },

  metaInfo () {
    return {
      title: 'Search Page',

      meta: [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Search' },
        { vmid: 'description', name: 'description', content: 'Gimme Recipe - A recipe generator written javascript' },
        { vmid: 'ogdescription', property: 'og:description', content: 'Gimme Recipe - A recipe generator written javascript' }
      ]
    }
  }
})
